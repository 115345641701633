<template>
  <div class="vista-escritorio" v-if="cargaTerminada">
    <cabecera />
    <cuerpo>
      <div class="votos ">
        <div class="votos-cabecera ">
          <div class="titulos ">

            <!-- <div class="titulos scroll"> -->
              <div  v-if="eleccion=='D'">
                <h2>
                  Diputaciones - <span>Detalle del Distrito</span>- <strong> Voto Anticipado</strong> 
                </h2>
              </div>
              <div  v-else>
                <h2>
                  Ayuntamiento  - <span>Detalle del Distrito</span>- <strong> Voto Anticipado</strong> 
                </h2>
              </div>
              
              
            <div>
              <h4>
                Baja California Sur / Voto Anticipado
              </h4> 
              
            </div>

            <div class="">
                <h6 style="font-size: 15px; font-weight: normal;">
                  El total de votos calculado y porcentaje que se muestran por Representación Proporcional
                  de cada Casilla Especial, se refiere a los votos asentados en las Actas PREP hasta el momento. <br />
                  Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.
                  No obstante, al considerar todos los decimales, suman 100%.
                </h6>
            </div>
          
            <votos-casilla-especial/>                     

          </div>          
        </div>
      </div>
  
     

      
    </cuerpo>
    <pie-de-pagina />
    <compartir />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import Compartir from '@/components/Compartir.vue';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas.vue';
import VotosCasillaEspecial from '@/components/Escritorio/vistas/VotosCasillaEspecial.vue';
import ProcedenciaActa from '@/components/ProcedenciaActa.vue';
import NivelProcedencia from '@/components/NivelProcedencia.vue';
import Cabecera from '../componentes/Cabecera.vue';
import Cuerpo from '../componentes/Cuerpo.vue';
import DetalleCasilla from '@/components/DetalleCasilla.vue';
import PieDePagina from '../componentes/PieDePagina.vue';
import VotosPorPartidoPoliticoTarjetas from '@/components/Escritorio/vistas/VotosPorPartidoPoliticoTarjetas.vue';


export default defineComponent({
  components: {
    Cabecera, 
    Compartir, 
    Cuerpo, 
    VotosCasillaEspecial,
    DetalleCasilla ,
    VotosPorPartidoPoliticoTarjetas,
    ObservacionesActas, 
    PieDePagina, 
    ProcedenciaActa, 
    NivelProcedencia,
  },
  name: 'VotoAnticipadoPorDistritoEscritorio',
  setup(_, { root }) {
    const state = computed(() => root.$store.state);
    const cargaTerminada = computed(() => state.value.cargaTerminada);
    const votosXRP = computed(() => state.value.dRepresentacionProporcional);
    const resumen = computed(() => state.value.dResumenNivelRepresentacionProporcional);
    const longitudResumen = resumen.value.datos_casillas.length;
    const resumenTotales = computed(() => resumen.value.datos_casillas[longitudResumen - 1]);

    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const votos = computed(() => votosXRP.value.datos_casillas
      .sort((a, b) => a.seccion_id - b.seccion_id));

    return {
      ...useCalcularUrlActaEscaneada(),
      cargaTerminada,
      votosXRP,
      resumen,
      votos,
      resumenTotales,
    };
  },
});
</script>

<style lang="scss" scoped>

.grilla-estado-acta {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  align-items: flex-end;

  .acta-digitalizada {
    .btn {
      background-color: #D2127E !important;
      border-color: transparent;
    }
  }
}

.table-responsive {
  thead {
    tr {
      background-color:#582D73;
      color: #FFF;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
          /* max-width: 35px; */
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-height: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          // font-weight: bold !important;
        }
      }
    }
  }
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #582D73;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}
</style>
