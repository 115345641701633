<template>
  <div>
    <VotoAnticipadoPorDistritoMovil v-if="anchoPantalla < 992" />
    <VotoAnticipadoPorDistritoEscritorio v-else />
  </div>
</template>

<script>
import VotoAnticipadoPorDistritoMovil from '@/components/Movil/vistas/VotoAnticipadoPorDistrito.vue'
import VotoAnticipadoPorDistritoEscritorio from '@/components/Escritorio/vistas/VotoAnticipadoPorDistrito.vue'

export default {
  name: 'RepresentacionProporcional',
  components: {
    VotoAnticipadoPorDistritoMovil,
    VotoAnticipadoPorDistritoEscritorio,
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2024 - Representación Proporcional'
  }
}
</script>
